body {
  margin: 0;
}

.top-pane {
  background-color: hsl(0, 4%, 28%);
  overflow: hidden;
}

.pane {
  height: 50vh;
  display: flex;
}

.editor-container {
  display: flex;
  flex-grow: 1;
  flex-direction:column;
  height:100%;
  margin:10px;
  transition: all 0.2s ease;
}

.editor-container.collapsed {
  flex-grow: 0;
}

.editor-container.collapsed .CodeMirror-scroll {
  position: absolute;
  overflow: hidden !important;
}

.expand-collapse-btn {
  margin-left: .5rem;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

.editor-title {
  background-color: rgb(21, 21, 21);
  padding:10px;
  display: flex;
  justify-content: space-between;
  border-bottom:1px solid hsl(0, 4%, 28%);
}

.CodeMirror {
  height: 100% !important;
  background-color: rgb(21, 21, 21)!important;
}

.code-mirror-wrapper {
  flex-grow: 1;
  border-bottom-right-radius: .5rem;
  border-bottom-left-radius: .5rem;
  overflow: hidden;
}